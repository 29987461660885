import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const sortField = [
  // {
  //   value: undefined,
  //   label: "正常",
  // },
  {
    value: "NEW",
    label: "最新",
  },
  {
    value: "HOT",
    label: "最热",
  },
];

const { map: sortFieldMap, setOps: sortFieldOps } = mapHelper.setMap(sortField);
// 是否关联
const isRelevanceOpt = [
  {
    name: 0,
    value: "否",
  },
  {
    name: 1,
    value: "是",
  },
];
//关联应用

const relevanceAppOpt = [
  {
    value: "1",
    label: "投票表决",
  },
  {
    value: "2",
    label: "问卷调查",
  },
  {
    value: "3",
    label: "抽奖",
  },
  {
    value: "4",
    label: "商品列表",
  },
  {
    value: "5",
    label: "活动列表",
  },
];
const { map: relevanceAppOptMap, setOps: relevanceAppOptOps } =
  mapHelper.setMap(relevanceAppOpt);
//运营位类型
const bannerType = [
  {
    value: 5,
    label: "小程序首页运营位",
  },
  {
    value: 47,
    label: "投票运营位",
  },
  {
    value: 48,
    label: "问卷运营位",
  },
  {
    value: 49,
    label: "社群运营位",
  },
  {
    value: 50,
    label: "托幼运营位",
  },
  {
    value: 51,
    label: "创业运营位",
  },
  {
    value: 52,
    label: "商圈运营位",
  },
  {
    value: 53,
    label: "广播运营位",
  },
  {
    value: 54,
    label: "超话运营位",
  },
  {
    value: 55,
    label: "党建运营位",
  },
];
const { map: bannerTypeMap, setOps: bannerTypeOps } =
  mapHelper.setMap(bannerType);
const bannerListType = [
  {
    value: 5,
    label: "小程序首页运营位",
  },
  {
    value: 47,
    label: "投票运营位",
  },
  {
    value: 48,
    label: "问卷运营位",
  },
  {
    value: 49,
    label: "社群运营位",
  },
  {
    value: 50,
    label: "托幼运营位",
  },
  {
    value: 51,
    label: "创业运营位",
  },
  {
    value: 52,
    label: "商圈运营位",
  },
  {
    value: 53,
    label: "广播运营位",
  },
  {
    value: 54,
    label: "超话运营位",
  },
  {
    value: 55,
    label: "党建运营位",
  },
  {
    value: "5,47,48,49,50,51,52,53,54,55",
    label: "全部",
  },
];
export {
  bannerListType,
  bannerType,
  bannerTypeMap,
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
};
